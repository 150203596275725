<template>
<div>
    <v-container fluid class="pa-0 parentDiv">
        <v-img class="bannerProducto" :src="require('@/assets/img/despensa/'+banner+'.png')" />
        <RouterLink to="/">
            <v-img class="backHome" max-width="40" :src="require('@/assets/img/btnHome.png')" />
        </RouterLink>
    </v-container>
    <v-container mt-8></v-container>
    <v-container>
        <v-img max-width="350" class="mx-auto" :src="require('@/assets/img/logos/LogoDespensa.png')" />
    </v-container>
    <v-container mt-5>
        <v-layout wrap>
            <v-flex xs12 md8 class="mx-auto">
                <v-layout wrap>
                    <v-flex xs12 md6 class="mx-auto">
                        <v-form v-model="valid" ref="formIdCliente" v-on:submit.prevent="">
                            <v-text-field v-model="idCliente" color="#0C55A6" :rules="reglas.idCliente" label="ID de cliente" outlined dense></v-text-field>
                            <v-btn @click="verificaIdCliente()" depressed small block dark color="#0C55A6">
                                Verifica tu ID cliente
                            </v-btn>
                        </v-form>
                        <p class="azul font-18 text-center mt-5" v-if="!existeCliente">
                            El ID de cliente viene en tu mail de Bienvenida, en caso de que no cuentes con el llámanos al 55 5481 6021 en la opción de dudas o aclaraciones.
                        </p>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-container>
    <Informacion :cliente="datosCliente" :existe="existeCliente" v-if="statusCliente != ''" />
    <Footer programa="Despensa" />
</div>
</template>

<script>
import Footer from "@/components/FooterPrograma.vue";
import Informacion from "@/components/despensa/Informacion.vue";

import axios from "axios";
import {
    backend
} from "@/back/global.js";

export default {
    name: "Clientedespensa",
    components: {
        Footer,
        Informacion
    },
    data: () => ({
        valid: true,
        idCliente: "",
        statusCliente: "",
        existeCliente: "",
        datosCliente: "",
        reglas: {
            idCliente: [
                value => !!value || "Campo requerido."
            ]
        }
    }),
    methods: {
        verificaIdCliente() {
            if (!this.$refs.formIdCliente.validate()) {
                return false;
            }
            this.$store.state.overlay = true;
            this.statusCliente = '';
            axios({
                    method: "post",
                    url: backend.url + "despensa/verificaIDCliente",
                    headers: backend.headers,
                    data: {
                        idCliente: this.idCliente
                    }
                })
                .then(response => {
                    let token = this.$jwt.decode(response.data.token);
                    switch (token.status) {
                        case 1:
                            if (token.data.cliente.statusPago == 'Pendiente' || token.data.cliente.statusPago == 'Posterior') {
                                this.$localStorage.set('token', response.data.token);
                            } else {
                                this.$localStorage.remove('token');
                            }

                            this.statusCliente = token.data.cliente.statusPago;
                            this.datosCliente = token.data;
                            this.existeCliente = true;

                            this.$snotify.success('Tu ID de cliente es correcto.', "Bienvenido", {
                                timeout: 3000,
                                showProgressBar: true,
                                position: "rightTop"
                            });
                            break;
                        case 0:
                            this.existeCliente = false;
                            this.$localStorage.set('token', '');
                            this.datosCliente = '';
                            this.statusCliente = 'No existe';
                            break;
                    }
                })
                .catch(error => {
                    this.$snotify.error('No pudimos verificar tu ID cliente ' + error, "Aviso", {
                        timeout: 3000,
                        showProgressBar: true,
                        position: "rightTop"
                    });
                }).finally(() => {
                    this.$store.state.overlay = false;
                });
        }
    },
    computed: {
        banner() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 'BannerDespensaMovil';
                case 'sm':
                    return 'BannerDespensaMovil';
                case 'md':
                    return 'BannerDespensa';
                case 'lg':
                    return 'BannerDespensa';
                case 'xl':
                    return 'BannerDespensa';
            }
        }
    }
}
</script>
