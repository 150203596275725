<template>
<div>
    <v-container>
        <v-layout wrap>
            <v-flex xs12 md8 class="mx-auto">
                <p class="rojo font-19 text-center" v-if="!existe">
                    Identificamos que no tienes contratado este producto
                </p>
                <div class="text-center" v-if="!existe">
                    <v-btn depressed small dark to="/compradespensa" color="#0C55A6">
                        Contrata aquí por $23 al mes
                    </v-btn>
                </div>
                <v-container mt-10></v-container>
                <p class="azul font-19 text-center mt-10" v-if="!existe">
                    ¡Protección Despensa te da la tranquilidad que necesitas con Asistencias y un Seguro que protege a los tuyos en caso de que faltes!
                </p>
                <p class="azul font-19 text-center" v-if="existe">
                    Tú y tu familia ya están protegidos con Asistencias que les respaldan en el día a día, y con un Seguro que protege a los tuyos en caso de que faltes.
                </p>
                <p class="azul font-19 text-justify" v-if="(existe && cliente.cliente.statusPago == 'Posterior') || (existe && cliente.cliente.statusPago == 'Pendiente')">
                    Tu vigencia termina el <b class="naranja">{{ cliente.cliente.finVigencia }}</b>, puedes realizar <RouterLink to="/compradespensa" class="naranja"><b>AQUÍ</b></RouterLink> tu pago para que tu protección continúe.
                </p>
                <p class="azul font-19 text-center" v-if="existe && cliente.cliente.statusPago == 'Pagado'">
                    Tu vigencia termina el <b class="naranja">{{ cliente.cliente.finVigencia }}</b>, si el siguiente cargo a tu tarjeta es exitoso seguirás contando con tu protección.
                </p>
                <div class="text-center" v-if="(existe && cliente.cliente.statusPago == 'Posterior') || (existe && cliente.cliente.statusPago == 'Pendiente')">
                    <v-btn depressed small dark to="/compradespensa" color="#0C55A6">
                        Contrata aquí por $23 al mes
                    </v-btn>
                </div>
                <div class="text-center" v-if="(existe && cliente.cliente.statusPago == 'Pagado' && cliente.url != null)">
                    <v-btn @click="confirmCancelarPago(cliente.url)" small dark color="#0C55A6">
                        Cancelar pago recurrente
                    </v-btn>
                </div>
                <p class="azul font-19 text-center mt-3" v-if="existe">
                    ¡Protección Despensa te da la tranquilidad que necesitas!
                </p>
            </v-flex>
        </v-layout>
    </v-container>
    <v-container>
        <v-layout wrap>
            <v-flex xs12 md4 class="pa-5">
                <Bloque liga="" textoLiga="" :beneficios="bienestar" img="despensa/MiBienestar.png" titulo="Mi Bienestar" descripcion="Te orientamos sobre temas relacionados con tu salud y la de tu familia." />
            </v-flex>
            <v-flex xs12 md4 class="pa-5">
                <Bloque liga="" textoLiga="" :beneficios="beneficiosMiAsistencia" img="despensa/MiAsistencia.png" titulo="Mi Asistencia" descripcion="Te brindamos orientación vía telefónica en caso de que necesites asesoría legal, apoyo escolar, atención médica veterinaria o enlace en casos de emergencia." />
            </v-flex>
            <v-flex xs12 md4 class="pa-5">
                <Bloque liga="" textoLiga="" :beneficios="beneficiosMiHogar" img="despensa/Mihogar.png" titulo="Mi Hogar" descripcion="Si tienes algún imprevisto en casa, te apoyamos con el fin de limitar y controlar los daños materiales." />
            </v-flex>
        </v-layout>
        <v-layout wrap>
            <v-flex xs12 md6>
                <v-layout wrap>
                    <v-flex xs12 md7 class="pa-2 mx-auto">
                        <Bloque liga="" textoLiga="" :beneficios="beneficiosMiCamino" img="despensa/MiCamino.png" titulo="Mi Camino" descripcion="Si tu automóvil presenta alguna avería menor, como descarga de batería, ponchadura de llanta, o falta de gasolina, te enviaremos a un prestador de servicios para realizar el servicio." />
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 md6>
                <v-layout wrap>
                    <v-flex xs12 md7 class="pa-2 mx-auto">
                        <Bloque liga="miseguro" textoLiga="Cómo uso el servicio de Mi Seguro" :beneficios="beneficiosMiSeguro" img="despensa/MiSeguro.png" titulo="Mi Seguro" descripcion="En caso de que el Titular llegue a fallecer por un accidente, se hará un pago por <span class='azul font-weight-bold'>$10,000.</span>" />
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
        <p class="naranja font-weight-bold font-18 text-center" v-if="existe">
            No esperes más y ¡comienza a utilizar tu Protección Despensa!
        </p>
        <p class="naranja font-weight-bold font-18 text-center" v-else>
            No esperes más y ¡contrata tu Protección Despensa ahora mismo!
        </p>
        <div class="text-center" v-if="!existe">
            <v-btn depressed small dark to="/compradespensa" color="#0C55A6">
                Contrata aquí por $23 al mes
            </v-btn>
        </div>
        <v-container mt-4 mb-4></v-container>
        <v-layout wrap>
            <v-flex xs12 md4 class="text-center pa-4 mx-auto">
                <v-btn color="#0c55a6" width="200" height="35" :href="'pdf/despensa/CondicionesGenerales.pdf'" download="CondicionesGeneralesDespensa.pdf" class="btnNoMayuscula" dark small>Condiciones Generales</v-btn>
            </v-flex>
            <v-flex xs12 md4 class="text-center pa-4" v-if="existe">
                <v-btn color="#0c55a6" width="200" height="35" :href="'pdf/despensa/Kit.pdf'" download="kitDigitalDespensa.pdf" class="btnNoMayuscula" dark small>Kit Digital</v-btn>
            </v-flex>
            <v-flex xs12 md4 class="text-center pa-4" v-if="existe">
                <v-btn color="#0c55a6" width="200" target="_blank" href="https://saludalamano.com.mx/saludChedraui/" height="35" class="btnNoMayuscula" dark small>Consulta Virtual</v-btn>
            </v-flex>
        </v-layout>
        <p class="text-center line-height mt-5">
            <span class="azul font-18">Para más información llámanos al:</span>
            <br>
            <span class="font-weight-bold naranja font-25"><a class="noList naranja" href="tel:5554816021">55 54 81 60 21</a></span>
            <br>
            <span class="azul font-12">Horario de atención: las 24 horas de los 365 días del año</span>
        </p>
    </v-container>
</div>
</template>

<script>
import Bloque from "@/components/BloqueBeneficio.vue";

export default {
    name: "Informacion",
    components: {
        Bloque
    },
    props: ["cliente", "existe"],
    data: () => ({
        imgFooter: '',
        beneficiosMiBienestar: ["Línea médica", "Línea psicológica", "Línea nutricional", "Línea dental", "Consulta virtual"],
        beneficiosMiAsistencia: ["Línea legal", "Línea escolar", "Línea médica veterinaria", "Enlace para emergencias"],
        beneficiosMiHogar: ["Plomero", "Electricista", "Cerrajero", "Vidriero"],
        beneficiosMiCamino: ["Cambio de llanta", "Envío de gasolina", "Paso de corriente", "Grúa"],
        beneficiosMiSeguro: []
    }),
    methods: {
        confirmCancelarPago(url) {
            this.$snotify.warning('Tu producto te cuesta solo $23 pesos al mes y cuentas con 18 servicios para ti y tu familia.\n\r¡Usa hoy mismo tus servicios!', '¿Estás seguro que no quieres seguir protegido?', {
                timeout: 50000,
                titleMaxLength: 100,
                bodyMaxLength: 300,
                showProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                position: "centerCenter",
                buttons: [{
                        text: 'Si, cancelar',
                        action: (toast) => {
                            location.href = url;
                        }
                    },
                    {
                        text: 'Cerrar ventana',
                        action: (toast) => {
                            this.$snotify.remove(toast.id);
                        },
                        bold: true
                    },
                ]
            });
        }
    },
    computed: {
        bienestar() {
            if (this.existe) {
                return ["Línea médica", "Línea psicológica", "Línea nutricional", "Línea dental", '<a href="https://saludalamano.com.mx/saludChedraui/" target="_blanck">Consulta virtual</a>'];
            } else {
                return this.beneficiosMiBienestar;
            }
        }
    }
}
</script>
